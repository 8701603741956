<template>
  <div>
    <div v-for="brand in brands" :key="brand.id" class="Input Input--mid Input--imgSmall">
      <input
        :id="'tirefilterbrand' + brand.id"
        v-model="brandFilter"
        type="checkbox"
        :name="'tirefilterbrand' + brand.id"
        :value="brand.id"
        :disabled="!brand.count"
      />
      <label :for="'tirefilterbrand' + brand.id"
        ><img :src="brand.logo" :alt="brand.id" />
        <div class="Input__count">{{ brand.count }}</div></label
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    brands: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    ...mapState('tirefilter', ['filtredBrands']),
    brandFilter: {
      set(val) {
        this.setFiltredBrands(val);
      },
      get() {
        return this.filtredBrands;
      },
    },
  },

  methods: {
    ...mapActions('tirefilter', ['setFiltredBrands']),
  },
};
</script>
