<template>
  <div
    class="TireSearch TireSearch--search"
    :class="[focusClass, { 'is-working': working }, { 'is-initialized': true }]"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      class="TireSearch__spinner Spinner Spinner--overlay Spinner--xl"
    >
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="50"
        cy="50"
        fill="none"
        stroke="#ff708e"
        stroke-width="8"
        r="30"
        stroke-dasharray="70"
      ></circle>
    </svg>
    <Message
      v-cloak
      title="Der skete en fejl"
      :show="notification === 'error'"
      :overlay="true"
      class="TireSearch__message"
      @close="clearNotification"
      >{{ $t('tireSearch.Error') }}</Message
    >
    <div class="TireSearch__sections">
      <div class="TireSearch__section">
        <div class="TireSearch__seasons">
          <div class="TireSearch__title">{{ $t('tireSearch.Season') }}</div>
          <div class="TireSearch__sectionContent">
            <ul>
              <li>
                <button
                  class="Button Button--small Button--light"
                  :class="{ 'is-active': isSeason(0) }"
                  @click="setSeason(0)"
                >
                  <i class="ikon-thghelaars"></i> {{ $t('tireSearch.SeasonHelaar') }}
                </button>
              </li>
              <li>
                <button
                  class="Button Button--small Button--light"
                  :class="{ 'is-active': isSeason(1) }"
                  @click="setSeason(1)"
                >
                  <i class="ikon-thgsommer" style="transform: translateX(-3px);"></i>
                  {{ $t('tireSearch.SeasonSommer') }}
                </button>
              </li>
              <li>
                <button
                  class="Button Button--small Button--light"
                  :class="{ 'is-active': isSeason(2) }"
                  @click="setSeason(2)"
                >
                  <i class="ikon-thgvinter"></i> {{ $t('tireSearch.SeasonVinter') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="TireSearch__section">
        <div class="TireSearch__sectionContent">
          <div class="TireSearch__selectors">
            <div class="TireSearch__selector">
              <div class="Input Input--select">
                <div class="TireSearch__title">{{ $t('tireSearch.Bredde') }}</div>
                <select
                  id="TireSearchSelectWidth"
                  v-model.number="filterWidth"
                  autofocus
                  tabindex="0"
                  @focus="setFocus(0)"
                  @blur="clearFocus"
                  @mouseover="setFocus(0)"
                  @mouseleave="clearFocus"
                >
                  <option value="-1">{{ $t('tireSearch.All') }}</option>
                  <option v-for="width in widths" :key="width[0]" :disabled="!width[1]">{{
                    width[0]
                  }}</option>
                </select>
              </div>
            </div>
            <div class="TireSearch__selector">
              <div class="Input Input--select">
                <div class="TireSearch__title">{{ $t('tireSearch.Profil') }}</div>
                <select
                  id="TireSearchSelectProfile"
                  v-model.number="filterProfile"
                  autofocus
                  tabindex="0"
                  @focus="setFocus(1)"
                  @blur="clearFocus"
                  @mouseover="setFocus(1)"
                  @mouseleave="clearFocus"
                >
                  <option value="-1">{{ $t('tireSearch.All') }}</option>
                  <option v-for="profile in profiles" :key="profile[0]" :disabled="!profile[1]">{{
                    profile[0]
                  }}</option>
                </select>
              </div>
            </div>
            <div class="TireSearch__selector">
              <div class="Input Input--select">
                <div class="TireSearch__title">{{ $t('tireSearch.Tommer') }}</div>
                <select
                  id="TireSearchSelectInches"
                  v-model.number="filterInches"
                  autofocus
                  tabindex="0"
                  @focus="setFocus(2)"
                  @blur="clearFocus"
                  @mouseover="setFocus(2)"
                  @mouseleave="clearFocus"
                >
                  <option value="-1">{{ $t('tireSearch.All') }}</option>
                  <option v-for="inch in inches" :key="inch[0]" :disabled="!inch[1]">{{
                    inch[0]
                  }}</option>
                </select>
              </div>
            </div>
            <div
              class="TireSearch__helper"
              style="background-image: url(https://cdn.thg.dk/DAT/dom/img/daek-stoerrelse-vaelger-new.png);"
            ></div>
          </div>
        </div>
      </div>
      <button class="Button TireSearch__submit" @click="submit">
        {{ $t('tireSearch.SoegBtn') }}
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import { mapActions, mapGetters } from 'vuex';

import { fetchWheels } from '@services/thansen/car';
import { CollapseTransition } from '@transitions';
import { Message } from '@components/UI';

import TireBrandsFilter from './components/TireBrandsFilter';

const FilterCookie = Cookies.withConverter({
  write(value, name) {
    return value;
  },
});

export default {
  components: {
    CollapseTransition,
    Message,
    TireBrandsFilter,
  },
  props: {
    loadOn: '',
    destination: '',
    defaultSeason: 0,
    selectedSeason: '',
    vehicleId: '',
    extendedSearch: false,
    router: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      season: 1,
      focus: '',
      working: false,
      tireCombinations: [],
      notification: '',
      updatingFilter: false,
      hideDisabled: true,
    };
  },

  watch: {
    // whenever question changes, this function will run
    season(newSeason, oldSeason) {
      if (newSeason !== oldSeason) {
        this.fetchWheels();
      }
    },
    filter: {
      deep: true,
      handler(newFilter, oldFilter) {
        // this.setCookie();
        // if (!this.updatingFilter) this.setCookie();
      },
    },
    $route(to, from) {
      this.updateFilterFromQuery();
    },
  },

  computed: {
    ...mapGetters('tirefilter', ['getDimensions', 'getBrands', 'getOffer', 'getSsr']),

    ssrFilter: {
      get() {
        return this.getSsr.checked;
      },
      set(val) {
        this.setSsrCheck(val);
      },
    },
    offerFilter: {
      get() {
        return this.getOffer.checked;
      },
      set(val) {
        this.setOfferCheck(val);
      },
    },

    filter() {
      return this.getDimensions();
    },

    filterWidth: {
      get() {
        return this.getDimensions().width;
      },
      set(width) {
        // Updates the query params if router is enabled.
        this.updateFilterQuery('width', width);
        this.setDimensions({ filter: { width } });
      },
    },

    filterProfile: {
      get() {
        return this.getDimensions().profile;
      },
      set(profile) {
        this.updateFilterQuery('profile', profile);
        this.setDimensions({ filter: { profile } });
      },
    },

    filterInches: {
      get() {
        return this.getDimensions().inches;
      },
      set(inches) {
        this.updateFilterQuery('inches', inches);
        this.setDimensions({ filter: { inches } });
      },
    },

    isSeason() {
      return season => {
        return Number(season) === this.season;
      };
    },

    focusClass() {
      return this.focus !== '' ? `has-focus-${this.focus}` : '';
    },

    widths() {
      return Object.entries(this.widthsObj);
    },

    widthsObj() {
      const widths = {};

      this.tireCombinations.forEach(tire => {
        if (!widths.hasOwnProperty(tire.width) || !widths[tire.width]) {
          const isAvaliable = true;

          if (!this.hideDisabled) {
            widths[tire.width] = isAvaliable;
          } else if (isAvaliable || this.filterWidth === tire.width) {
            widths[tire.width] = true;
          }
        }
      });

      return widths;
    },

    profiles() {
      return Object.entries(this.profilesObj);
    },

    profilesObj() {
      const profiles = {};

      this.tireCombinations.forEach(tire => {
        if (!profiles.hasOwnProperty(tire.profile) || !profiles[tire.profile]) {
          const isAvaliable = this.filterWidth === -1 || this.filterWidth === tire.width;
          // && (this.filterInches === -1 || this.filterInches === tire.inches);

          if (!this.hideDisabled) {
            profiles[tire.profile] = isAvaliable;
          } else if (isAvaliable || this.filterProfile === tire.profile) {
            profiles[tire.profile] = true;
          }
        }
      });

      return profiles;
    },

    inches() {
      return Object.entries(this.inchesObj);
    },

    inchesObj() {
      const inches = {};

      this.tireCombinations.forEach(tire => {
        if (!inches.hasOwnProperty(tire.inches) || !inches[tire.inches]) {
          const isAvaliable =
            (this.filterWidth === -1 || this.filterWidth === tire.width) &&
            (this.filterProfile === -1 || this.filterProfile === tire.profile);

          if (!this.hideDisabled) {
            inches[tire.inches] = isAvaliable;
          } else if (isAvaliable || this.filterInches === tire.inches) {
            inches[tire.inches] = true;
          }
        }
      });

      return inches;
    },
  },

  methods: {
    ...mapActions('tirefilter', ['setDimensions', 'setSsrCheck', 'setOfferCheck']),

    /**
     * Updates the query param for a specific filter
     */
    updateFilterQuery(type, value) {
      if (!this.router) return false;
      if (String(this.$route.query[type]) !== String(value)) {
        this.$router.replace({
          query: { ...this.$route.query, ...{ [type]: value } },
        });
      }
    },

    /**
     * Updates the dimensions filter from the query params.
     */
    updateFilterFromQuery() {
      if (this.$route.query) {
        if (this.$route.query.width) this.filterWidth = Number(this.$route.query.width);
        if (this.$route.query.inches) this.filterInches = Number(this.$route.query.inches);
        if (this.$route.query.profile) this.filterProfile = Number(this.$route.query.profile);
      }
    },

    setSeason(season) {
      this.season = Number(season);
    },

    setFocus(focus) {
      this.focus = focus;
    },

    clearFocus() {
      this.focus = '';
    },

    fetchWheels() {
      const workingTimer = setTimeout(() => {
        this.working = true;
      }, 240);

      fetchWheels({ season: this.season, vehicleId: this.vehicleId })
        .then(response => {
          clearTimeout(workingTimer);

          this.working = false;
          this.tireCombinations = response.tire_combinations;
        })
        .catch(() => {
          // Retry?
          clearTimeout(workingTimer);
          this.working = false;
          this.notificaiton = 'error';
        });
    },

    clearNotification() {
      this.notification = '';
    },

    submit() {
      this.working = true;

      let url = `${this.destination}${this.destination.indexOf('?') !== -1 ? '&' : '?'}sa=${
        this.season
      }`;

      // Appends dimensions filters if they are set.
      if (this.filterWidth && this.filterWidth != -1) url += '&width=' + this.filterWidth;
      if (this.filterInches && this.filterInches != -1) url += '&inches=' + this.filterInches;
      if (this.filterProfile && this.filterProfile != -1) url += '&profile=' + this.filterProfile;

      document.location = url;
    },

    onExtendedSearchToggle(e) {
      this.working = true;
      const url = window.location.href;
      // An url helper would be nice

      if (url.indexOf('bs=') !== -1) {
        document.location = url.replace(/([?&]bs=)[^?&]?/g, `$1${e.target.checked ? 1 : 0}`);
      } else if (url.indexOf('?') !== -1) {
        // This should not happen
        // document.location = `${url}&bs=${e.target.checked}` ? 1 : 0;
      } else {
        // This should not happen
        const bs = e.target.checked ? '1' : '0';

        document.location = `${url}?bs=${bs}`;
      }
    },
  },

  created() {
    if (this.router) this.updateFilterFromQuery();

    this.season =
      this.selectedSeason !== '' ? Number(this.selectedSeason) : Number(this.defaultSeason);
  },

  mounted() {
    if (this.loadOn === 'mounted') {
      this.fetchWheels();
    }
  },
};
</script>
